import React from 'react'
import JobForm from '../components/formpage/JobForm'
import { Helmet } from 'react-helmet-async';

export default function JobFormPage() {
  return (
    <>
    <Helmet>
      <title>Apply for the latest jobs on Disamina AI</title>
      <meta name="description" content="Discover exciting latest job opportunities near you and apply now." />
      <meta name="keywords" content="latest jobs, open positions" />
    </Helmet>
    <JobForm/>
    </>
  )
}
