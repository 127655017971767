import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    alljobs: [],
    selectedJob: {}
//   id: 0,
//   jobCode: "000",
//   jobTitle: "Frontend Developer",
//   location: "Bhopal",
//   category: "Developer",
//   qualification: "Engineering",
//   experienceRequired: 2,
//   jobType: "Fulltime",
//   jobDescription: "Description",
//   companyDescription: "company",
//   republish: true,
//   workType: "Hybrid",
//   jobSkills: []
}

export const jobSlice = createSlice({
  name: "jobData",
  initialState,
  reducers: {
    setAllJobs: (state, action) => {
      state.alljobs = action.payload;
    },
    setSelectedJob: (state, action) => {
      state.selectedJob = action.payload;
    },
  },
});

export const {
  setAllJobs, setSelectedJob
} = jobSlice.actions;
export default jobSlice.reducer;
