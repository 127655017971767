import axios from 'axios';

// Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://dev-disamina-api-g2byg9ewgxfqa7fg.eastus2-01.azurewebsites.net',
  timeout: 10000, // Timeout if desired
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Add token to request headers if available
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // For example, handle 401 errors by redirecting to login
      // window.location.href = '/login';
    } else if (error.response && error.response.status === 403) {
      // Handle forbidden errors
      console.error('Access Denied');
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
