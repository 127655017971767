import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// import logo from "../assets/images/logo.png";
import fulllogo from "../assets/images/jobs-logo.png";
import { TbAlignBoxLeftMiddle, TbAlignBoxRightMiddle } from 'react-icons/tb'
import { FiBriefcase } from "react-icons/fi";

export default function Layout() {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [show, setShow] = useState(false)

  return (
    <div className="d-flex flex-column min-vh-100 vw-100">
      <header className="p-2 header">
        <div className="container d-flex justify-content-between align-items-center">
          <div className="" role="button" onClick={() => navigate("/")}>
            {/* <img className="mx-1" src={logo} alt="logo" width={40} /> */}
            <img className="mx-1" src={fulllogo} alt="fulllogo" width={100} />
          </div>
          <div className="d-none d-lg-block">
            <div className="d-flex justify-content-between align-items-end">
              <div className="mx-3 header-link" onClick={()=>navigate('/')}>Jobs</div>
              <a className="tdn" href="https://disamina.ai/contact/">
                <div className="mx-3 header-link">Support</div>
              </a>
              <a className="tdn" href="https://disamina.ai/">
                <div className="mx-3 header-link">Back to Disamina</div>
              </a>
            </div>
          </div>
          <div className="d-none d-lg-block">
            <a href="https://portal.disamina.ai/signin">
              <button className="btn btn-pill">
                <FiBriefcase className="mb-1 me-2"/>
                Post a Job
              </button>
            </a>
          </div>
          <div className="d-block d-lg-none">
            <div className="text-purple" onClick={() => setShow(!show)} role="button">
              {
                !show ? <TbAlignBoxLeftMiddle size={40} /> : <TbAlignBoxRightMiddle size={40} />
              }
            </div>
          </div>
        </div>
        {/* <nav>
          <Link to="/">Home</Link> | <Link to="/jobdetails">Details</Link> | <Link to="/contact">Contact</Link>
        </nav> */}
      </header>
      <main className="position-relative">
        <div className={`sidebar ${show ? "sidebar-visible":""} position-absolute d-block d-lg-none"`}>
          <div className="m-3">
            <a href="https://portal.disamina.ai/signin">
              <button className="btn btn-pill">
                Post a Job
              </button>
            </a>
          </div>
              <div className="m-3 header-link" onClick={()=>{setShow(false); navigate('/')}}>Jobs</div>
              <a className="tdn" href="https://disamina.ai/contact/">
                <div className="m-3 header-link" onClick={()=>setShow(false)}>Support</div>
              </a>
              <a className="tdn" href="https://disamina.ai/">
                <div className="m-3 header-link" onClick={()=>setShow(false)}>Back to Disamina</div>
              </a>
        </div>
          <Outlet /> {/* Renders the nested route component */}
      </main>
      <footer className="footer">
        Copyright © {currentYear} Disamina. All rights reserved.
      </footer>
    </div>
  );
}
