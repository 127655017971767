import React from 'react';
import DOMPurify from 'dompurify';

export default function HtmlContent ({ htmlContent }) {
  // Configure DOMPurify to remove `<img>` tags
  const cleanHtmlContent = DOMPurify.sanitize(htmlContent, {
    FORBID_TAGS: ['img'] // This will remove all <img> tags
  });

  return (
    <div
      dangerouslySetInnerHTML={{ __html: cleanHtmlContent }}
    />
  );
};
