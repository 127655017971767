import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/comman.scss";
import "./assets/scss/custom.scss";

import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import HomePage from "./pages/HomePage";
import JobDetailPage from "./pages/JobDetailPage";
import Error404 from "./pages/Error404";
import JobFormPage from "./pages/JobFormPage";
import ErrorBoundary from "./ErrorBoundary";
import ScrollToTop from "./components/shared/ScrolltoTop";
import CompanyPage from "./pages/CompanyPage";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Add the event listener for the window load event
    window.addEventListener('load', ()=>setLoading(false));
    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('load', ()=>setLoading(false));
  }, []);
  return (
    loading ? 
      <div className="center">
        <span className="loader"></span>
      </div>
      :
      <BrowserRouter>
        <ErrorBoundary>
          <ScrollToTop/>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<HomePage />} />
              <Route path="/:clid/:customerName/:jobTitle/:jobCode" element={<JobDetailPage />} />
              <Route path="/:clid" element={<CompanyPage />} />
              <Route path="jobform" element={<JobFormPage />} />
              <Route path="*" element={<Error404 />} />
            </Route>
          </Routes>
        </ErrorBoundary>
      </BrowserRouter>
  );
}

export default App;
