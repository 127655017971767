import React from "react";

export default function HeroSection() {
  return (
    <div className="text-center">
      <div className="hero-title">
        Your perfect job could be waiting for you
      </div>
      <div className="hero-sub">
        Your ideal job is just a click away. Search open positions from leading employers worldwide.
      </div>
      {/* <div className="my-3">
        <button className="btn btn-pill">Explore Jobs</button>
      </div> */}
    </div>
  );
}
