import React, { useEffect, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import JobCard from "../components/homepage/JobCard";
import { Helmet } from "react-helmet-async";
import HeroSection from "../components/homepage/HeroSection";
import JobFilter from "../components/homepage/JobFilter";
import axiosInstance from "../utils/axios";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { resetJobFilter } from "../redux/slice/jobfilterSlice";
import { useDispatch } from "react-redux";
// import Pagination from "../components/shared/Pagination";
// import { setAllJobs } from "../redux/slice/jobSlice";
import { useParams } from "react-router-dom";

export default function CompanyPage() {
  const dispatch = useDispatch();
  const { clid } = useParams();

  let categories = ["All"];

  const filter = useSelector((state) => state.jobFilter);
  //   const jobList = useSelector((state) => state.jobData.alljobs);

  const [jobList, setJobList] = useState();
  const [loading, setLoading] = useState(true);
  const [filterJobList, setFilterJobList] = useState();
  const [searchKeyword, setSearchKeyword] = useState();
  const [activepill, setActivePill] = useState(0);
  const [activeCategory, setActiveCategory] = useState("All");

  if (jobList) {
    const unique_categories = [
      ...new Set(jobList.map((item) => item.category)),
    ]; //that ...new Set will remove duplicate values
    categories = [...categories, ...unique_categories];
  }

  const fetchData = async () => {
    try {
      const reqbody = {
        pageNo: 1,
        pageSize: 4,
        searchText: null,
        isActive: true,
        experience: null,
        location: null,
        title: null,
        clientId: clid,
      };
      const response = await axiosInstance.post(`/api/Job/all-jobs`, reqbody);
      setFilterJobList(response.data);
      setJobList(response.data);
      //   dispatch(setAllJobs(response.data));
      setLoading(false);
    } catch (error) {
      toast.error("Error fetching data\n" + error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const searchFilter = () => {
    setActivePill(0);
    dispatch(resetJobFilter());
    const filteredJobs = jobList.filter(
      (job) =>
        job.jobTitle.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        job.jobDescription.toLowerCase().includes(searchKeyword.toLowerCase())
    );
    setFilterJobList(filteredJobs);
  };

  const categoryFilter = () => {
    setSearchKeyword("");
    if (activeCategory !== "All") {
      const list = jobList.filter((item) => item.category === activeCategory);
      setFilterJobList(list);
    } else {
      dispatch(resetJobFilter());
      setFilterJobList(jobList);
    }
  };

  const filterJobs = () => {
    setSearchKeyword("");
    let filterApplied = false; // important for resetting the filter
    let filterList = jobList; // this is a final filter list initiallised with all list
    // first filter out the category if any selected
    if (activeCategory !== "All") {
      filterList = jobList.filter((item) => item.category === activeCategory);
    }
    if (filter.full_time) {
      filterApplied = true;
      filterList = filterList.filter((item) => item.jobType === "Full Time");
    }
    if (filter.part_time) {
      filterApplied = true;
      filterList = filterList.filter((item) => item.jobType === "Part Time");
    }
    if (filter.contract) {
      filterApplied = true;
      filterList = filterList.filter((item) => item.jobType === "Contract");
    }
    if (filter.internship) {
      filterApplied = true;
      filterList = filterList.filter((item) => item.jobType === "Internship");
    }
    if (filter.entry_level) {
      filterApplied = true;
      filterList = filterList.filter((item) => item.experienceRequired <= 2);
    }
    if (filter.intermediate) {
      filterApplied = true;
      filterList = filterList.filter(
        (item) => item.experienceRequired > 2 && item.experienceRequired <= 5
      );
    }
    if (filter.expert_level) {
      filterApplied = true;
      filterList = filterList.filter((item) => item.experienceRequired > 5);
    }
    if (filter.on_site) {
      filterApplied = true;
      filterList = filterList.filter((item) => item.workType === "Onsite");
    }
    if (filter.hybrid) {
      filterApplied = true;
      filterList = filterList.filter((item) => item.workType === "Hybrid");
    }
    if (filter.remote) {
      filterApplied = true;
      filterList = filterList.filter((item) => item.workType === "Remote");
    }
    if (!filterApplied) {
      setActivePill(0);
      setActiveCategory("All");
      setFilterJobList(jobList);
    } else {
      setFilterJobList(filterList);
    }
  };

  useEffect(() => {
    categoryFilter();
    // eslint-disable-next-line
  }, [activeCategory]);

  useEffect(() => {
    filterJobs();
    // eslint-disable-next-line
  }, [filter]);

  //Pagination states and functions
  // const [localState, setLocalState] = useState({
  //   pageNo: 1,
  //   pageSize: 4,
  //   currentPageNo: 1,
  //   rowPerPage: 4,
  // });

  // const handlePageNoChange = (check) => {
  //   if (check === "previous") {
  //     setLocalState((state) => ({
  //       ...state,
  //       currentPageNo: localState.currentPageNo - 1,
  //     }));
  //   } else if (check === "next") {
  //     setLocalState((state) => ({
  //       ...state,
  //       currentPageNo: localState.currentPageNo + 1,
  //     }));
  //   }
  // };

  // const handleRowPerPageChange = (value) => {
  //   const temp = parseInt(value);
  //   setLocalState((state) => ({ ...state, rowPerPage: temp }));
  // };

  // const temp = parseInt(
  //   localState.rowPerPage * (localState.currentPageNo - 1) +
  //     localState.rowPerPage,
  //   10
  // );

  return (
    <>
      <Helmet>
        <title>Job Board - Disamina</title>
        <meta name="description" content="The next big thing is here." />
        <meta name="keywords" content="jobs, hiring, best" />
      </Helmet>
      <div className="container">
        {/* Hero */}
        <HeroSection />

        {/* Job Search Bar */}
        <div className="d-flex justify-content-center">
          <div className="input-search-group">
            <input
              type="text"
              className="form-control input-search"
              placeholder="Search for job title or enter keyword"
              aria-label="Job Search Bar"
              aria-describedby="button-addon2"
              value={searchKeyword}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
              }}
            />
            <button
              className="btn btn-pill input-search-button"
              type="button"
              id="button-addon2"
              onClick={searchFilter}
            >
              <IoSearchOutline size={25} />
            </button>
          </div>
        </div>

        {/* Job Category Mapping */}
        <div className="row my-3  ">
          <div className="d-flex flex-wrap justify-content-center">
            {categories.map((item, index) => (
              <div
                key={index}
                className={`category-pill ${
                  activepill === index ? "active-pill" : ""
                }`}
                role="button"
                onClick={() => {
                  setActivePill(index);
                  setActiveCategory(item);
                }}
              >
                {item}
              </div>
            ))}
          </div>
        </div>

        {/* Job Cards */}
        <div className="row my-3 g-3" id="jobs-section">
          <div className="col-sm-12 col-md-4 col-lg-2">
            <JobFilter />
          </div>
          <div className="col-sm-12 col-md-8 col-lg-10">
            <div className="row g-3">
              {loading ? (
                <div className="mt-5 pt-5 text-center">
                  <span className="loader"></span>
                </div>
              ) : (
                filterJobList &&
                filterJobList.map((item, index) => (
                  <div key={index} className="col-sm-12 col-md-12 col-lg-6">
                    <JobCard data={item} />
                  </div>
                ))
              )}
            </div>
          </div>
          {/* <div className="text-end">
            <Pagination 
              totalCount={filterJobList?.length}
              handleRowPerPageChange={handleRowPerPageChange}
              handlePageNoChange={handlePageNoChange}
              currentPageNo={localState.currentPageNo}
              rowPerPage={localState.rowPerPage}
            />
          </div> */}
        </div>

        <div className="mb-5"></div>
      </div>
    </>
  );
}
