import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Accordion from "react-bootstrap/Accordion";
import {
  resetJobFilter,
  setJobFilter_EntryLevel,
  setJobFilter_ExpertLevel,
  setJobFilter_FullTime,
  setJobFilter_Hybrid,
  setJobFilter_Intermediate,
  setJobFilter_Contract,
  setJobFilter_Internship,
  setJobFilter_OnSite,
  setJobFilter_PartTime,
  setJobFilter_Remote,
  resetJobType,
  resetExpLevel,
  resetWorkType,
} from "../../redux/slice/jobfilterSlice";

export default function JobFilter() {
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.jobFilter);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/* <div className="d-flex justify-content-between align-items-center">
        <div className="fw-500">Show By</div>
        <div
          className="btn btn-link tdn fs-12"
          onClick={() => dispatch(resetJobFilter())}
        >
          Clear all
        </div>
      </div> */}

      {/* job type filter */}
      <Accordion
        defaultActiveKey={windowWidth > 576 ? "0" : ""}
        className="position-sticky"
        style={{ top: "90px" }}
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header>Search By</Accordion.Header>
          <Accordion.Body>
            <div className="text-end">
              <button
                className="btn btn-link tdn fs-12 text-end"
                onClick={() => dispatch(resetJobFilter())}
              >
                Clear all
              </button>
            </div>
            <div className="fs-14">
              {/* Job Type */}
              <div className="mb-3">
                <div className="my-2 fw-600">Job Type</div>
                <div className="my-1 d-flex align-items-center">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    checked={filter.full_time}
                    onChange={() => {
                      dispatch(resetJobType());
                      dispatch(setJobFilter_FullTime(!filter.full_time));
                    }}
                  />
                  <span className="mx-2">Full Time</span>
                </div>
                <div className="my-1 d-flex align-items-center">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    checked={filter.part_time}
                    onChange={() => {
                      dispatch(resetJobType());
                      dispatch(setJobFilter_PartTime(!filter.part_time));
                    }}
                  />
                  <span className="mx-2">Part Time</span>
                </div>
                <div className="my-1 d-flex align-items-center">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    checked={filter.internship}
                    onChange={() => {
                      dispatch(resetJobType());
                      dispatch(setJobFilter_Internship(!filter.internship));
                    }}
                  />
                  <span className="mx-2">Internship</span>
                </div>
                <div className="my-1 d-flex align-items-center">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    checked={filter.contract}
                    onChange={() => {
                      dispatch(resetJobType());
                      dispatch(setJobFilter_Contract(!filter.contract));
                    }}
                  />
                  <span className="mx-2">Contract</span>
                </div>
              </div>

              {/* Experience Level */}
              <div className="mb-3">
                <div className="my-2 fw-600">Experience Level</div>
                <div className="my-1 d-flex align-items-center">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    checked={filter.entry_level}
                    onChange={() => {
                      dispatch(resetExpLevel());
                      dispatch(setJobFilter_EntryLevel(!filter.entry_level));
                    }}
                  />
                  <span className="mx-2">Entry Level</span>
                </div>
                <div className="my-1 d-flex align-items-center">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    checked={filter.intermediate}
                    onChange={() => {
                      dispatch(resetExpLevel());
                      dispatch(setJobFilter_Intermediate(!filter.intermediate));
                    }}
                  />
                  <span className="mx-2">Intermediate</span>
                </div>
                <div className="my-1 d-flex align-items-center">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    checked={filter.expert_level}
                    onChange={() => {
                      dispatch(resetExpLevel());
                      dispatch(setJobFilter_ExpertLevel(!filter.expert_level));
                    }}
                  />
                  <span className="mx-2">Expert</span>
                </div>
              </div>

              {/* Job Location */}
              <div className="mb-3">
                <div className="my-2 fw-600">Workplace Type</div>
                <div className="my-1 d-flex align-items-center">
                  <input
                    className="form-check-input"
                    value=""
                    type="checkbox"
                    checked={filter.on_site}
                    onChange={() => {
                      dispatch(resetWorkType());
                      dispatch(setJobFilter_OnSite(!filter.on_site));
                    }}
                  />
                  <span className="mx-2">Onsite</span>
                </div>
                <div className="my-1 d-flex align-items-center">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    checked={filter.remote}
                    onChange={() => {
                      dispatch(resetWorkType());
                      dispatch(setJobFilter_Remote(!filter.remote));
                    }}
                  />
                  <span className="mx-2">Remote</span>
                </div>
                <div className="my-1 d-flex align-items-center">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    checked={filter.hybrid}
                    onChange={() => {
                      dispatch(resetWorkType());
                      dispatch(setJobFilter_Hybrid(!filter.hybrid));
                    }}
                  />
                  <span className="mx-2">Hybrid</span>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
