import React from "react";
import { useLocation } from "react-router-dom";
import { GoAlertFill } from "react-icons/go";

export default function ErrorMessage() {
	const location = useLocation();
  return (
    <div className="container">
      <div className="my-5 py-5 d-flex justify-content-center align-items-center">
        <div className="text-center">
					<GoAlertFill size={100} className="text-danger"/>
          <h1>Oops...</h1>
          <h4 className="text-secondary">Something Went Wrong</h4>
					<p>There was a problem connecting to Disamina.</p>
					<div className="">Please <a className='' href={location.pathname}>refresh</a> the page or check your connection</div>
          <div className="">If problem still persists, try <a className='' href={'/'}>reloading</a> the site</div>
        </div>
      </div>
    </div>
  );
}
