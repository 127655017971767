import React from "react";
import { LuSearchX } from "react-icons/lu";

export default function Nodata() {
  return (
    <div className="container">
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "200px" }}
      >
        <div className="text-center">
          <div className="">
            <LuSearchX size={25} className="mx-2 mb-2 text-danger" />
            <span className="fs-22">No Data Found</span>
          </div>
          <span className="fs-14">Please try different search criteria</span>
        </div>
      </div>
    </div>
  );
}
