import React, {useState, useRef} from "react";
// import companyLogo from "../../assets/images/logo.png";
// import { LuDot } from "react-icons/lu";
import { MdOutlineFileUpload } from "react-icons/md";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import { FaCircleCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { FiChevronLeft, FiCheck } from "react-icons/fi";
import { AiFillCloseCircle } from "react-icons/ai";

export default function JobForm() {
  const formData = useSelector((state) => state.formData);
  const job = useSelector((state) => state.jobData.selectedJob);
  
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [submissionError, setSubmissionError] = useState(false)

  const formikRef = useRef(null)

  const handleFormSubmit = async(values, token) => {
    setProcessing(true)
    setShowModal(true)
    const formData = new FormData();
    formData.append("JobId", job.id);
    formData.append("TokenKey", token);
    formData.append("FullName", values.fullname);
    formData.append("PhoneNumber", values.phone_number);
    formData.append("EmailId", values.email);
    formData.append("NoticePeriod", values.notice_period);
    formData.append("TotalExperience", values.exp_years);
    formData.append("CurrentOrganization", values.organisation);
    formData.append("LinkedinUrl", values.linkedin);

    // Append each skillId and ExperiennceInSKills separately
    values.skills.forEach((item) => {
      formData.append("skillId", item.skillId);
      formData.append("ExperiennceInSKills", item.yearsOfExperience);
  });
    
    formData.append("resumeFile", values.resume);

    try {
      const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/api/Application', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Access-Control-Allow-Origin': '*',
        },
      });
      if(response) {
        toast.success("Form Data submitted successfully!")
        setProcessing(false);
      }
    } catch (error) {
      setProcessing(false);
      setSubmissionError(true);
      toast.error(error.response?.data.message)
    }
  }

  const externalSubmit = () => {
    if (formikRef.current) {
      formikRef.current.handleSubmit(); // Trigger form submission
    }
  }

  return (
    <div className="container">
      <div className="mt-4 d-flex align-items-center justify-content-between">
        <div className="ms-2 fs-14">
          <span className='bc-link' onClick={()=>navigate('/')}>Jobs</span>
          <span className='mx-2'>{`>`}</span>
          <span className='bc-link' onClick={()=>navigate(`/${job.clientId}`)}>
            {job?.customerName ? job.customerName : "Customer Name"}
          </span>
          <span className='mx-2'>{`>`}</span>
          <span>{job?.jobTitle ? job.jobTitle : "Job Title"}</span>
        </div>
        <div className="fw-500 text-purple me-5 d-none d-md-block" role="button"
        onClick={()=>navigate('/')}
        >
          <FiChevronLeft className="me-1"/>
          All Jobs
        </div>
      </div>
      <div className="form-container my-4 bg-white">
        <div className="row">
          {/* <div className="d-flex justify-content-between align-items-center">
            <div className="m-3 d-flex align-items-center">
              <div className="company-logo">
                <img src={job.logoPath ? job.logoPath : companyLogo} alt="company logo" width={50} />
              </div>
              <div className="mx-2">
                <div className="role-title">{job?.jobTitle ? job.jobTitle : "Job Title"}</div>
                <div className="d-flex align-items-center jc-sub-text">
                  <div className="company-name">{job.customerName ? job.customerName : "Comapany Name"}</div>
                  <div className="dot">
                    <LuDot />
                  </div>
                  <div className="location">{job.location ? job.location : "All India"}</div>
                </div>
              </div>
            </div>
            <div className="fw-500 text-purple me-5 d-none d-md-block" role="button"
              onClick={()=>navigate(-1)}
            >
              <FiChevronLeft className="me-1"/>
              Back
            </div>
          </div>
          <div className="fw-500 text-purple ms-3 mb-2 d-md-none d-sm-block" role="button"
              onClick={()=>navigate(-1)}
            >
              <FiChevronLeft className="me-1"/>
              Back
            </div> */}
        </div>
        <div className="line-one"></div>
        {/* Form Start */}
        <Formik
          innerRef={formikRef}
          initialValues={formData}
          validate={(values) => {
            const errors = {};
            if (!values.fullname) {
              errors.fullname = "Required";
            }
            if (!values.phone_number) {
              errors.phone_number = "Required";
            } else if (
              !/^\+?[0-9]{1,3}?[-. ]?[0-9]{1,5}?[-. ]?[0-9]{1,5}$/i.test(values.phone_number)
            ) {
              errors.phone_number = "Invalid Phone Number";
            }
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            if (!values.exp_years) {
              errors.exp_years = "Required";
            }
            if(values.exp_years) {
              let decimal = values.exp_years.toString().substring(2, 4);
              if(decimal > 11) {
                errors.exp_years = "Months cannot be greater than 11";
              }
            }
            if (!values.notice_period) {
              errors.notice_period = "Required";
            }
            if (values.notice_period > 90) {
              errors.notice_period = "Max 90 days Notice period is allowed"
            }
            if (!values.resume) {
              errors.resume = "Required";
            } else if (values.resume.size > (4 * 1000000)) {
              errors.resume = "File size upto 4 MB allowed";
            }
            if(!values.terms) {
              errors.terms = "Required";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            window.grecaptcha.ready(async() => {
              await window.grecaptcha
                .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
                  action: "submit",
                })
                .then((token) => {
                  handleFormSubmit(values, token) // here form final submit
                })
                .catch((error) => {
                  toast.error("reCAPTCHA execution error");
                });
            });
            setTimeout(() => {
              // alert(JSON.stringify(values, null, 2));
              setSubmitting(false);
            }, 4000);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => (
            <form className="row" onSubmit={handleSubmit}>
              {/* Fullname */}
              <div className="col-sm-12 col-md-6">
                <div className="p-3">
                  <label className="ms-2 label-one">
                    Full Name
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="input"
                    // value={}
                    className="input-one"
                    placeholder="Full Name"
                    name="fullname"
                    id="fullname"
                    required
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <div className="my-1 text-danger fs-14">
                    {errors.fullname && touched.fullname && errors.fullname}
                  </div>
                </div>
              </div>
              {/* Phone Number */}
              <div className="col-sm-12 col-md-6">
                <div className="p-3">
                  <label className="ms-2 label-one">
                    Phone Number
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    // value={adv.neg_marks !== 0 ? adv.neg_marks : ""}
                    // min="0"
                    // max="100"
                    className="input-one"
                    placeholder="Phone Number"
                    name="phone_number"
                    id="phone_number"
                    required
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // onChange={(e) => {
                    //   let num = parseInt(e.target.value, 10);
                    //   dispatch(setNegMarks(num));
                    // }}
                  />
                  <div className="my-1 text-danger fs-14">
                    {errors.phone_number &&
                      touched.phone_number &&
                      errors.phone_number}
                  </div>
                </div>
              </div>
              {/* Email */}
              <div className="col-sm-12 col-md-6">
                <div className="p-3">
                  <label className="ms-2 label-one">
                    Email
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="input"
                    // value={}
                    className="input-one"
                    placeholder="Email Address"
                    name="email"
                    id="email"
                    required
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <div className="my-1 text-danger fs-14">
                    {errors.email && touched.email && errors.email}
                  </div>
                </div>
              </div>
              {/* Linkedin Url */}
              <div className="col-sm-12 col-md-6">
                <div className="p-3">
                  <label className="ms-2 label-one">
                    LinkedIn/Git Url
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <input
                    type="input"
                    // value={}
                    className="input-one"
                    placeholder="LinkedIn/Git Url"
                    name="linkedin"
                    id="linkedin"
                    // required
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {/* <div className="my-1 text-danger">
                    {errors.fullname && touched.fullname && errors.fullname}
                  </div> */}
                </div>
              </div>
              {/* Total Experience flex */}
              <div className="col-sm-12 col-md-6">
                <div className="p-3">
                  <label className="ms-2 label-one">
                    Total Experience (in years)
                    <span className="text-danger">*</span>
                  </label>
                  <input
                      type="number"
                      // value={adv.neg_marks !== 0 ? adv.neg_marks : ""}
                      // min="0"
                      // max="60"
                      step="0.01"
                      className="input-one"
                      placeholder="7.5"
                      name="exp_years"
                      id="exp_years"
                      required
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <div className="my-1 text-danger fs-14">
                      {errors.exp_years}
                      {/* {errors.exp_years && touched.exp_years && errors.exp_years} */}
                    </div>
                </div>
              </div>
              {/* Notice Period */}
              <div className="col-sm-12 col-md-6">
                <div className="p-3">
                  <label className="ms-2 label-one">
                    Notice Period (in days)
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    // value={adv.neg_marks !== 0 ? adv.neg_marks : ""}
                    min="0"
                    max="90"
                    className="input-one"
                    placeholder="Notice Period"
                    name="notice_period"
                    id="notice_period"
                    required
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // onChange={(e) => {
                    //   let num = parseInt(e.target.value, 10);
                    //   dispatch(setNegMarks(num));
                    // }}
                  />
                  <div className="my-1 text-danger fs-14">
                    {errors.notice_period &&
                      touched.notice_period &&
                      errors.notice_period}
                  </div>
                </div>
              </div>
              {/* Organisation */}
              <div className="col-sm-12 col-md-6">
                <div className="p-3">
                  <label className="ms-2 label-one">
                    Current Organisation
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <input
                    type="input"
                    // value={}
                    className="input-one"
                    placeholder="Current Organisation"
                    name="organisation"
                    id="organisation"
                    // required
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {/* <div className="my-1 text-danger">
                    {errors.fullname && touched.fullname && errors.fullname}
                  </div> */}
                </div>
              </div>
              {/* Resume */}
              <div className="col-sm-12 col-md-6">
                <div className="p-3">
                  <label className="ms-2 label-one">
                    Resume
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    hidden
                    type="file"
                    accept=".pdf,.doc,.docx"
                    // value={values.name}
                    className=""
                    // placeholder="Upload Resume"
                    name="resume"
                    id="resume"
                    required
                    onBlur={handleBlur}
                    onChange={e=>setFieldValue("resume", e.currentTarget.files[0])}
                  />
                  <label
                    htmlFor="resume"
                    className="input-one"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="fs-14">
                        {values.resume ? (
                          values.resume.name.length > 20 ?
                          values.resume.name.slice(0, 20) + '...' :
                          values.resume.name
                        ) : (
                          <span style={{ color: "#757575" }}>
                            Upload Resume
                          </span>
                        )}
                      </div>
                      <div className="upload-badge">
                        <MdOutlineFileUpload className="me-2"/>
                        Choose File
                      </div>
                    </div>
                  </label>
                  <div className={`my-1 fs-14 ${errors.resume ? "text-danger" : "text-secondary"}`}>
                    {errors.resume ? errors.resume : "Pdf/docx/doc file only. Max file size 4 MB"}
                  </div>
                </div>
              </div>
              {/* Photo */}
              {/* <div className="col-sm-12 col-md-6">
                <div className="p-3">
                  <label className="ms-2 label-one">
                    Photo
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    hidden
                    type="file"
                    // value={}
                    className=""
                    placeholder="Upload your photo"
                    name="photo"
                    id="photo"
                    // required
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="photo"
                    className="input-one"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="">
                        {values.photo ? (
                          values.photo
                        ) : (
                          <span style={{ color: "#aeadad" }}>
                            Upload your photo
                          </span>
                        )}
                      </div>
                      <div className="upload-badge">
                        <MdOutlineFileUpload />
                        upload
                      </div>
                    </div>
                  </label>
                  <div className="my-1 text-danger">
                    {errors.photo && touched.photo && errors.photo}
                  </div>
                </div>
              </div> */}
              {/* ID Card */}
              {/* <div className="col-sm-12 col-md-6">
                <div className="p-3">
                  <label className="ms-2 label-one">
                    ID Card
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    hidden
                    type="file"
                    // value={}
                    className=""
                    placeholder="Upload your ID Card"
                    name="idcard"
                    id="idcard"
                    // required
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="idcard"
                    className="input-one"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="">
                        {values.idcard ? (
                          values.idcard
                        ) : (
                          <span style={{ color: "#aeadad" }}>
                            Upload your ID Card
                          </span>
                        )}
                      </div>
                      <div className="upload-badge">
                        <MdOutlineFileUpload />
                        upload
                      </div>
                    </div>
                  </label>
                  <div className="my-1 text-danger">
                    {errors.idcard && touched.idcard && errors.idcard}
                  </div>
                </div>
              </div> */}
              {/* Skills */}
              <div className="col-sm-12 col-md-6">
                <div className="p-3">
                  <label className="ms-2 label-one">
                    Relevant Experience (in years)
                    <span className="text-danger">*</span>
                  </label>
                  {job.jobSkills &&
                    job.jobSkills.map((item, index) => (
                      <div key={index} className="d-flex gap-1">
                        <input
                          type="input"
                          value={item.skillName ? item.skillName : "job skills"}
                          className="input-one"
                          // placeholder="HTML/CSS/JavaScript etc"
                          // name={`skills[${index}].name`}
                          // id={`skills[${index}].name`}
                          // required
                          disabled
                          // onBlur={handleBlur}
                          // onChange={()=>setFieldValue(`skills[${index}].name`, item.skillId)}
                          // onChange={(e) => {
                          //   let num = parseInt(e.target.value, 10);
                          //   dispatch(setNegMarks(num));
                          // }}
                        />
                        {/* <div className="text-danger">
                          {errors.skill_name &&
                            touched.skill_name &&
                            errors.skill_name}
                        </div> */}
                        <input
                          type="number"
                          // value={adv.neg_marks !== 0 ? adv.neg_marks : ""}
                          min="0"
                          max="12"
                          className="input-one"
                          style={{ width: "65%" }}
                          // placeholder="Exp. in years"
                          name={`skills[${index}].exp`}
                          id={`skills[${index}].exp`}
                          required
                          onBlur={handleBlur}
                          onChange={(e)=>{
                            setFieldValue(`skills[${index}].skillId`, item.skillId);
                            setFieldValue(`skills[${index}].yearsOfExperience`, parseInt(e.currentTarget.value), 10);
                          }}
                          // onChange={(e) => {
                          //   let num = parseInt(e.target.value, 10);
                          //   dispatch(setNegMarks(num));
                          // }}
                        />
                        <div className="text-danger">
                          {errors.skill_year &&
                            touched.skill_year &&
                            errors.skill_year}
                        </div>

                        {/* {index > 1 ? (
                          <div className="me-1 mt-2 delete-button">
                            <button
                              className="btn btn-icon"
                              onClick={() =>
                                dispatch(
                                  setSkills(formData.skills.slice(0, -1))
                                )
                              }
                            >
                              <IoTrashBin size={25} />
                            </button>
                          </div>
                        ) : null} */}
                      </div>
                    ))}
                  {/* <div className="">
                    <button
                      className="my-2 btn btn-card"
                      disabled={formData.skills.length > 10}
                      onClick={() =>
                        dispatch(
                          setSkills([
                            ...formData.skills,
                            { name: "", year: "" },
                          ])
                        )
                      }
                    >
                      <CgAddR className="me-2 mb-1" />
                      Add
                    </button>
                  </div> */}
                </div>
              </div>
              <div className="m-3 d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="terms"
                      id="terms"
                      value=""
                      // checked={}
                      required
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <span className="mx-2 fs-14">By Submitting details on Disamina you must agree to the 
                    {" "}<a href="https://disamina.ai/terms-of-use/">terms & conditions</a>{" "}
                       and <a href="https://disamina.ai/privacy-policy/">privacy policy</a></span>
              </div>
              {/* Submit Button */}
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <button
                    className="m-3 btn btn-card"
                    // style={{ width: "100%" }}
                    type="submit"
                    disabled={isSubmitting}
                  > 
                    <FiCheck className="me-2"/>
                    Submit Details
                  </button>
                  <span className="text-danger fs-14">{errors.resume? "Please upload Resume": ""}</span>
                  {/* <p className="ms-3 fs-14 text-secondary">
                    <i>*Make sure to check your details before submitting</i>
                  </p> */}
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>

      <Modal
      show={showModal} 
      onHide={() => setShowModal(false)}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Please Verify data before proceed...
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
        <div className="p-5">
          {
            processing ?
          <div className="d-flex justify-content-center align-items-center">
            <div className="">
            <span className="loader"></span>
            </div>
            <span className="mx-3 vr-orange"></span>
            <div className="">
              <div className="fs-22">Please wait . . .</div> 
              <div className="">We are processing your application</div>  
            </div>
          </div>
            :
            submissionError ?
            <div className="">
              <div className="d-flex justify-content-center align-items-center">
                <div className="">
                  <AiFillCloseCircle size={50} className="text-danger"/>
                </div>
                <span className="mx-3 vr-danger"></span>
                <div className="">
                  <div className="fs-22">Something went wrong!</div> 
                  <div className="text-secondary">Your job application has not been sent due to some temporary issue.</div>  
                </div>
              </div>
              <div className="mt-4 text-center">
                {/* <div className="my-3 text-danger fw-500">error message if needed</div> */}
                <button className="mx-2 btn btn-pill" onClick={()=>{setSubmissionError(false); setProcessing(true); externalSubmit()}}>
                  Please try again
                </button>
                <button className="mx-2 btn btn-pill" onClick={()=>{setProcessing(false); setShowModal(false); setSubmissionError(false)}}>
                  Close
                </button>
              </div>
            </div>
            : //on success
            <div className="">
              <div className="d-flex justify-content-center align-items-center">
                <div className="">
                  <FaCircleCheck size={50} className="text-success"/>
                </div>
                <span className="mx-3 vr-success"></span>
                <div className="">
                  <div className="fs-22">Application Submitted Successfully!</div> 
                  <div className="text-secondary">Your job application has been sent to the respective recruiter for further process.</div>  
                </div>
              </div>
              <div className="mt-4 text-center">
                <div className="my-3 text-purple fs-22 fw-500">Apply for more similar positions.</div>
              <button className="btn btn-pill" onClick={()=>{setShowModal(false); navigate('/')}}>
                Browse more Jobs
              </button>
              </div>
            </div>
          
          }
          </div>
        </Modal.Body>
      </Modal>

      <div className="mb-5"></div>
    </div>
  );
}
