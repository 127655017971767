import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  all_selected: false,
  full_time: false,
  part_time: false,
  contract: false,
  internship: false,
  entry_level: false,
  intermediate: false,
  expert_level: false,
  on_site: false,
  remote: false,
  hybrid: false,
};

export const jobfilterSlice = createSlice({
  name: "jobFilter",
  initialState,
  reducers: {
    setJobFilter_AllSelected: (state, action) => {
      state.all_selected = action.payload;
    },
    setJobFilter_FullTime: (state, action) => {
      state.full_time = action.payload;
    },
    setJobFilter_PartTime: (state, action) => {
      state.part_time = action.payload;
    },
    setJobFilter_Contract: (state, action) => {
      state.contract = action.payload;
    },
    setJobFilter_Internship: (state, action) => {
      state.internship = action.payload;
    },
    setJobFilter_EntryLevel: (state, action) => {
      state.entry_level = action.payload;
    },
    setJobFilter_Intermediate: (state, action) => {
      state.intermediate = action.payload;
    },
    setJobFilter_ExpertLevel: (state, action) => {
      state.expert_level = action.payload;
    },
    setJobFilter_OnSite: (state, action) => {
      state.on_site = action.payload;
    },
    setJobFilter_Remote: (state, action) => {
      state.remote = action.payload;
    },
    setJobFilter_Hybrid: (state, action) => {
      state.hybrid = action.payload;
    },
    resetJobFilter: (state) => {
      return initialState;
    },
    resetJobType: (state) => {
      state.full_time = false;
      state.part_time = false;
      state.internship = false;
      state.contract = false;
    },
    resetExpLevel: (state) => {
      state.entry_level = false;
      state.intermediate = false;
      state.expert_level = false;
    },
    resetWorkType: (state) => {
      state.on_site = false;
      state.remote = false;
      state.hybrid = false;
    },
  },
});

export const {
  setJobFilter_AllSelected,
  setJobFilter_EntryLevel,
  setJobFilter_ExpertLevel,
  setJobFilter_FullTime,
  setJobFilter_Hybrid,
  setJobFilter_Intermediate,
  setJobFilter_Contract,
  setJobFilter_Internship,
  setJobFilter_OnSite,
  setJobFilter_PartTime,
  setJobFilter_Remote,
  resetJobFilter,
  resetJobType,
  resetExpLevel,
  resetWorkType,
} = jobfilterSlice.actions;
export default jobfilterSlice.reducer;
