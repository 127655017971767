import React, { useEffect, useState } from 'react'
import companyLogo from "../assets/images/logo.png"
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axiosInstance from "../utils/axios";
import toast from "react-hot-toast";
import { useDispatch } from 'react-redux';
import { setSelectedJob } from '../redux/slice/jobSlice';
import HtmlContent from '../components/shared/HtmlContent';
import { usFormatDate } from '../utils/formatdate';
import { FiCheck } from "react-icons/fi";
import { FiChevronLeft } from "react-icons/fi";
import { FiMapPin, FiNavigation, FiBriefcase, FiAward, FiClock } from "react-icons/fi";
import { FiExternalLink } from "react-icons/fi";
import { FaLinkedin } from "react-icons/fa";
import { isValidLink } from '../utils/regex';

export default function JobDetailPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobCode, clid } = useParams();
  
  const [job, setJob] = useState();

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`/api/Job/GetPublicJobById/${jobCode}`);
      setJob(response.data);
      dispatch(setSelectedJob(response.data));
    } catch (error) {
      toast.error('Error fetching data\n' + error.message);
    }
  };

  useEffect(()=>{
    fetchData()
    // eslint-disable-next-line
  },[])
  
  return (
    <>
    { !job ? 
      <div className="mt-5 pt-5 text-center">
        <span className="loader"></span> 
      </div>
    :
    <>
      <Helmet>
      <title>{`Latest ${job.jobTitle ? job.jobTitle : "Jobs - Disamina" } Jobs in ${job.location ? job.location : "All India"}`}</title>
      <meta name="description" content={`Search and apply for ${job.jobTitle ? job.jobTitle : "Jobs - Disamina" } jobs in ${job.location ? job.location : "All India"}. Hiring ${job.jobTitle ? job.jobTitle : "Jobs - Disamina" } at ${job.customerName ? job.customerName : "Comapany Name"}`} />
      <meta name="keywords" content={`${job.jobTitle ? job.jobTitle : "Jobs - Disamina" }`} />
      </Helmet>
      <div className='container my-4'>
        <div className="row g-3">
          <div className="d-flex align-items-center justify-content-between">
              <div className="ms-2 fs-14 w-75">
                <span className='bc-link' onClick={()=>navigate('/')}>Jobs</span>
                <span className='mx-2'>{`>`}</span>
                <span className='bc-link' onClick={()=>navigate(`/${clid}`)}>
                  {job?.customerName ? job.customerName : "Customer Name"}
                </span>
                <span className='mx-2'>{`>`}</span>
                <span className='text-break'>{job?.jobTitle ? job.jobTitle : "Job Title"}</span>
              </div>
              <div className="fw-500 text-purple me-5 d-none d-md-block" role="button"
              onClick={()=>navigate(-1)}
              >
                <FiChevronLeft className="me-1"/>
                All Jobs
              </div>
            </div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <div className="job-detail-card">
              <div className="title-bar p-4">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="role-title justify-content-start text-break w-75">
                    {job?.jobTitle ? job.jobTitle : "Job Title"}
                  </div>
                  <div className="d-none d-md-block">
                    <button className='btn btn-card'
                      onClick={()=>navigate('/jobform')}
                    >
                      <FiCheck className='me-2'/>
                      Apply Now
                    </button>
                  </div>
                  {/* <div className="d-flex justify-content-end align-items-center">
                    <div className="d-none d-md-block mx-4">
                      <span className='badge-blue'>{job?.jobType ? job.jobType : "Job Type"}</span>
                      <span className='badge-pink'>{job?.workType ? job.workType : "Work Type"}</span>
                      <span className='badge-green'>{job?.jobType ? `Exp : ${job.experienceRequired} years` : "Job Type"}</span>
                    </div>  
                  </div> */}
                </div>
                <div className="mt-2 d-flex justify-content-start align-items-center fs-12 flex-wrap">
                  <span><FiMapPin className="mb-1"/> {job?.location ? job.location : "Location"}</span>
                  <span className="mx-3">|</span>
                  <span><FiClock className="mb-1"/> {job?.jobType ? job.jobType : "Job Type"}</span>
                  <span className="mx-3">|</span>
                  <span><FiNavigation className="mb-1"/> {job?.workType ? job.workType : "Work Type"}</span>
                  <span className="mx-3">|</span>
                  <span><FiBriefcase className="mb-1"/> {job?.jobType ? `Experience : ${job.experienceRequired} years` : "Experience"}</span>
                  <span className="mx-3">|</span>
                  <span><FiAward className="mb-1"/> {job.qualification ? job.qualification : "Job Qualification"}</span>
                </div>
                {/* For Mobile view only */}
                    {/* <div className="mt-3 d-block d-md-none d-flex flex-wrap">
                      <span className='badge-blue'>{job?.jobType ? job.jobType : "Job Type"}</span>
                      <span className='badge-pink'>{job?.workType ? job.workType : "Work Type"}</span>
                      <span className='badge-green'>{job?.jobType ? `Exp : ${job.experienceRequired} years` : "Job Type"}</span>
                    </div> */}
              </div>
              <div className="line-one"></div>
              <div className="px-4 pt-4">
                <div className="jd-sub-title"><b>Job Description</b></div>
                <div className="jd-desc-box pt-2 text-break">
                  {job.jobDescription ? <HtmlContent htmlContent={job.jobDescription}/> : "Job Description"}
                </div>
              </div>
              <div className="px-4 pb-2 date-one">
                {`Posted on ${job.createdDate ? usFormatDate(job.createdDate) : "month dd, yyyy"}`}
              </div>
              <div className="px-4 pb-4">
                <button className='btn btn-card'
                  onClick={()=>navigate('/jobform')}
                >
                  <FiCheck className='me-2'/>
                  Apply Now
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <div className="side-card-one mb-3">
              <div className="d-flex">
                <div className="company-logo">
                  <img src={job.logoPath ? job.logoPath : companyLogo} alt='company-logo' width={50} />
                </div>
                <div className="ms-2">
                  <div className="sco-com-name">{job.customerName ? job.customerName : "Comapany Name"}</div>
                  <div className="sco-location">{job.customerLocation ? job.customerLocation : "Location"}</div>
                </div>
              </div>
              <div className="my-3 sco-desc text-break">
                {job.companyDescription ? <HtmlContent htmlContent={job.companyDescription}/> : "Description"}
              </div>
              <div className="">
                {job.websiteUrl && isValidLink(job.websiteUrl) &&
                <a className='btn-plain-icon' href={job.websiteUrl} target='_blank' rel="noreferrer">
                  <FiExternalLink className='me-2' size={25}/>
                </a>}
                {job.linkedInUrl && isValidLink(job.linkedInUrl) &&
                <a className='btn-plain-icon' href={job.linkedInUrl} target='_blank' rel="noreferrer">
                  <FaLinkedin className='me-2' size={25}/>
                </a>}
                <button className='btn btn-card'
                  onClick={()=>navigate(`/${clid}`)}
                >
                  <FiBriefcase className='me-2'/>
                  Open Positions
                </button>
              </div>
            </div>
            <div className="side-card-two mb-3">
              <div className="sct-title">Required Skills</div>
              <div className="my-2 sct-skills">
                <div className="d-flex flex-wrap">
                  {
                    job.jobSkills && 
                    job.jobSkills.map((item, index)=>
                      <div key={index} className='badge-skill'>{item.skillName ? item.skillName : "HTML"}</div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-5"></div>
      </div>
    </>
    }
   </> 
  )
}
