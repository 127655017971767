import { configureStore } from "@reduxjs/toolkit";
import { formSlice } from "./slice/formSlice";
import { jobfilterSlice } from "./slice/jobfilterSlice";
import { jobSlice } from "./slice/jobSlice";

export default configureStore({
  reducer: {
    formData: formSlice.reducer,
    jobFilter: jobfilterSlice.reducer,
    jobData: jobSlice.reducer,
  },
});
