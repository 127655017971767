import React from 'react'
import companyLogo from "../../assets/images/logo.png"
import { FiMapPin, FiNavigation, FiBriefcase } from "react-icons/fi";
import { RiListView } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetJobFilter } from '../../redux/slice/jobfilterSlice';
import { usFormatDate } from '../../utils/formatdate';

export default function JobCard({data}) {
const dispatch = useDispatch();
const navigate = useNavigate();
const encodedCompanyName = encodeURIComponent(data.customerName);
const encodedTitle = encodeURIComponent(data.jobTitle);

  return (
    <div className='job-card'
	onClick={()=>{
		dispatch(resetJobFilter());
		navigate(`/${data.clientId}/${encodedCompanyName}/${encodedTitle}}/${data.jobCode}`)
	}}
	>
      <div className="my-2">
				<div className="d-flex align-items-center">
					<div className="company-logo">
						<img src={data.logoPath ? data.logoPath : companyLogo} alt="company logo" width={50}/>
					</div>
					<div className="mx-2">
						<div className="role-title text-break">
							{data.jobTitle ? data.jobTitle : "Job Title"}
						</div>
						<div className="d-flex align-items-center jc-sub-text">
							<div className="company-name">{data.customerName ? data.customerName : "Disamina"}</div>
						</div>
					</div>
				</div>
				<div className="mt-4 text-secondary fs-14">
					
					<div className="location">
						<FiMapPin className='mb-1 me-2'/>
						{data.location ? data.location: "All India"}
					</div>
					<span><FiNavigation className='mb-1 me-2'/></span>
					<span>{data.jobType ? data.jobType : "Fulltime"}</span>
					<span className='mx-2'>|</span>
					<span>{data.workType ? data.workType : "Remote"}</span>
					<div className="">
						<FiBriefcase className='mb-1 me-2'/>
						{`Experience : ${data.experienceRequired ? data.experienceRequired : 0} years`}
					</div>
				</div>
				<div className="mt-2">
					<div className="d-flex justify-content-between align-items-center">
						<div className="date-one"><i>{`Posted on ${data.createdDate ? usFormatDate(data.createdDate):"month dd, yyyy"}`}</i></div>
						<div className="">
							<button className='btn btn-card'
								onClick={()=>{
									dispatch(resetJobFilter());
									navigate(`/${data.clientId}/${encodedCompanyName}/${encodedTitle}}/${data.jobCode}`)
								}}
							>
								<RiListView className='me-2'/>
								View
							</button>
						</div>
					</div>
				</div>
			</div>
    </div>
  )
}
