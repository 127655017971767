export const formatDate = (dateStr) => {
	const date = new Date(dateStr);
	const formattedDate = `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}/${date.getFullYear()}`;
	return formattedDate;
}

export const usFormatDate =(dateStr) => {
	const date = new Date(dateStr);
	const options = { year: 'numeric', month: 'long', day: '2-digit' };
	const formattedDate = date.toLocaleDateString('en-US', options);
	return formattedDate; // Output: "November 09, 2024"
}